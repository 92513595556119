


<div class="mx-auto">
    <form [formGroup]="customerData">
        <div class="flex justify-center  w-full">
            <div class="grid grid-cols-7 items-center gap-5 w-full">
                <!-- <div class="md:col-auto col-span-12 font-bold text-lg text-blue-600">
                    Manage Customers
                </div> -->
                <!-- <div class="md:col-auto col-span-12 font-bold text-lg">
                </div> -->
                <div class="md:col-auto col-span-12">
                    <input type="text" class=" rounded-xl border-gray-200 py-1 px-2 w-full border-2 " placeholder="Search..."
                    (input)="search($event)">
                </div>

                
            </div>
        </div>
    </form>
</div>

<div class="my-5 ">
    <div class="flex flex-col">
        <div class="overflow-x-auto shadow-md sm:rounded-lg">
            <div class="inline-block min-w-full align-middle">
                <div class="overflow-hidden ">
                    <table class="min-w-full divide-y divide-gray-200 table-fixed ">
                        <thead class="bg-gray-100 ">
                            <tr>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    First Name</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Last Name</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Gender </th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    DOB</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Birth Time</th>
                                <!-- <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Country</th> -->
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    MobileNo</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Status</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    EntryDate</th>
                                    <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Deleted Date</th>
                                <th scope="col"
                                    class="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase">
                                    Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="customerList?.length!==0;else noData"
                            class=" bg-white divide-y divide-gray-200 text-center">
                            <tr *ngFor="let item of customerList" class="hover:bg-gray-100">
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.FirstName ? item?.FirstName : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.LastName ? item?.LastName : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.Gender ? item?.Gender : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.DOB ? item?.DOB : "N/A"}}
                                </td>
                                <!-- <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.BirthTime}}
                                </td> -->
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.BirthTime ? item?.BirthTime : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.MobileNo ? item?.MobileNo : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left"
                                    [ngClass]="item?.Status?'text-green-500':'text-red-500'">
                                    {{item?.Status?"Active":"InActive"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.EntryDate ? item?.EntryDate : "N/A"}}
                                </td>
                                <td class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap text-left">
                                    {{item?.EntryDate ? item?.deleted_at : "N/A"}}
                                </td>
                                <td role="link" class="py-4 px-6 text-sm font-medium whitespace-nowrap text-left">
                                    <a mat-menu-item routerLink="../customer-view/customer-profile" 
                                        [queryParams]="{id:item?.CustomerId}" 
                                        class="" target="_blank"
                                    >
                                        View
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                        <ng-template #noData>
                            <tbody>
                                <td colspan="12"
                                    class="py-4 px-6 text-sm font-medium text-gray-500 whitespace-nowrap pl-[50px]"
                                    style="font-size: 14px">
                                   Please search for deleted customers
                                </td>
                            </tbody>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>

</div>






  