import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { AdminService } from 'src/app/shared/services/admin-api/admin-api.service';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { NotifierService } from 'src/app/shared/services/toaster.service';

@Component({
  selector: 'app-deleted-customer',
  templateUrl: './deleted-customer.component.html',
  styleUrls: ['./deleted-customer.component.css']
})
export class DeletedCustomerComponent {
  customerData: FormGroup
  customerList: any = [];
  modelChanged: Subject<any> = new Subject<any>();

  constructor(
    private _AdminService: AdminService,
    private _FormBuilder: FormBuilder,
    private _SpinnerService: SpinnerService,
    private _NotifierService: NotifierService,
    private modalService: BsModalService
  ) {
    this.customerData = this._FormBuilder.group({
      search: "",

    })
  }

  ngOnInit(): void {
    this.modelChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe((val) => {
      // this.customerData.get('page')?.setValue(1);
      this.customerData.get('search')?.setValue(val?.search);
      this.fetchCustomers()
    });

  }

  fetchCustomers() {
    this._SpinnerService.setState(true);
    this._AdminService.getDeletedCustomer(this.customerData.value).then((data) => {
      if (data?.status) {
        this.customerList = data?.data;
        this._SpinnerService.setState(false);
      }
    });
  }

  search(event: any) {
    this.modelChanged.next({ search: event.target.value })
  }
}
